import React from "react"
interface Introduction {
  introduction: string
}

const Introduction: React.FC<Introduction> = ({ introduction }) => (
  <div dangerouslySetInnerHTML={{ __html: introduction }} />
)

export default Introduction
