import React, { useEffect } from "react";
import SimpleQuestion from "./SimpleQuestion";
import MatrixQuestions from "./MatrixQuestions";
import {
  ClientUserAssessmentsClient,
  ClientUserFormDto,
  FormType,
  IClientUserAssessmentQuestionSlotDto,
} from "@api";
import { Form, Grid, FormProps, Row, Col } from "antd";
import { showError } from "@action";
interface ICustomProp {
  formPage: number;
  questionNumber: number;
  currentForm: ClientUserFormDto;
  assessmentId: number;
  currentFormId: number;
  questionSlots: IClientUserAssessmentQuestionSlotDto[];
  setQuestionSlots: (values: IClientUserAssessmentQuestionSlotDto[]) => void;
  setIsLoading: (value: boolean) => void;
}

interface IAssessmentForm extends ICustomProp, FormProps {}

const AssessmentForm: React.FC<IAssessmentForm> = ({
  formPage,
  currentForm,
  assessmentId,
  currentFormId,
  setIsLoading,
  questionSlots,
  questionNumber,
  setQuestionSlots,
  form,
  ...rest
}) => {
  const layout = {
    labelCol: { xl: 12, xs: 24 },
    wrapperCol: { xl: 12, xs: 24 },
  };
  const { xl, xs } = Grid.useBreakpoint();

  useEffect(() => {
    const fetchQuestions = async (
      pageNumber: number,
      id: number,
      formId: number
    ) => {
      setIsLoading(true);
      const client = new ClientUserAssessmentsClient();
      const result = await client
        .getQuestionSlot(id, id, formId, pageNumber)
        .catch((err) => {
          showError(err);
          return [];
        });

      setQuestionSlots(result.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
    };
    fetchQuestions(formPage, assessmentId, currentFormId);
  }, [formPage, assessmentId, currentFormId]);

  return (
    <Form
      labelWrap
      layout={xl ? "horizontal" : "vertical"}
      labelAlign="left"
      scrollToFirstError={{ behavior: "smooth", block: "center" }}
      colon={false}
      form={form}
      {...layout}
      {...rest}
    >
      <Row>
        <Col span={24} style={{ marginBottom: "24px" }}>
          <div
            dangerouslySetInnerHTML={{ __html: currentForm?.header ?? "" }}
          />
        </Col>

        {currentForm.type === FormType.Matrix ? (
          <Col span={24}>
            <MatrixQuestions
              nextPage={() => form?.submit()}
              ratingScale={currentForm.ratingScale}
              questionSlots={
                !xs
                  ? questionSlots
                  : questionSlots[questionNumber]
                  ? [questionSlots[questionNumber]]
                  : []
              }
            />
          </Col>
        ) : !xs ? (
          questionSlots.map(
            (questionSlot: IClientUserAssessmentQuestionSlotDto) => (
              <SimpleQuestion
                nextPage={() => form?.submit()}
                name={`questionSlot${questionSlot.id}`}
                key={`questionSlot${questionSlot.id}`}
                questionSlotId={questionSlot.id}
                questionAnswer={questionSlot}
                {...questionSlot.formQuestion}
              />
            )
          )
        ) : (
          <SimpleQuestion
            nextPage={() => form?.submit()}
            name={`questionSlot${questionSlots[questionNumber]?.id}`}
            key={`questionSlot${questionSlots[questionNumber]?.id}`}
            questionSlotId={questionSlots[questionNumber]?.id}
            questionAnswer={questionSlots[questionNumber]}
            {...questionSlots[questionNumber]?.formQuestion}
          />
        )}

        <Col span={24}>
          <div
            dangerouslySetInnerHTML={{ __html: currentForm?.footer ?? "" }}
          />
        </Col>
      </Row>
    </Form>
  );
};
export default AssessmentForm;
