import React, { ReactNode, useReducer, useEffect, useRef } from "react";
import { QuestionSlot } from "lib";
import { Col, FormItemProps, Grid } from "antd";
import {
  IClientUserFormQuestionDto,
  ClientUserAssessmentsClient,
  ClientUserQuestionChoicesClient,
  IClientUserQuestionChoiceDto,
  UpdateClientUserAssessmentQuestionSlotCommand,
  QuestionType,
} from "@api";
import { showError, findAnswer, translateAnswer } from "@action";

interface ICustomProp {
  options?: { label: string | ReactNode; value: string | number | boolean }[];
  inputProp?: any;
  questionAnswer?: {
    textAnswer?: string | undefined;
    questionChoiceId?: number | undefined;
    ratingScalePointId?: number | undefined;
  };
  questionQuestionChoiceListId?: number;
  questionSlotId?: number;
  nextPage: () => void;
}

interface IQuestionSlot
  extends ICustomProp,
    IClientUserFormQuestionDto,
    FormItemProps<any> {}

const SimpleQuestion: React.FC<IQuestionSlot> = ({
  questionContent,
  questionType,
  questionSlotId,
  questionQuestionChoiceListId = null,
  questionAnswer = null,
  nextPage,
  ...rest
}) => {
  interface Action {
    type: "fetchOptions" | "isLoading" | "submit";
    payload?: any;
  }

  const { xl, xs } = Grid.useBreakpoint();
  const divRef = useRef<HTMLDivElement>(null);
  const initialAnswer = findAnswer(questionType, questionAnswer);

  const initialState = {
    currentAnswer: initialAnswer,
    options: [],
    isLoading: false,
    isTouch: false,
  };

  const reducer = (state: any, { type, payload }: Action) => {
    switch (type) {
      case "fetchOptions":
        return {
          ...state,
          options: payload
            .sort(
              (
                a: IClientUserQuestionChoiceDto,
                b: IClientUserQuestionChoiceDto
              ) => (a?.order ?? 0) - (b?.order ?? 0)
            )
            .map((item: IClientUserQuestionChoiceDto) => ({
              label: item.content,
              value: item.id,
              disabled: item.isPlaceHolder,
            })),
          isLoading: false,
        };

      case "isLoading":
        return {
          ...state,
          isLoading: payload,
        };

      case "submit":
        return {
          ...state,
          currentAnswer: payload,
          isTouch: true,
        };

      default:
        break;
    }
  };

  const [{ currentAnswer, options, isLoading, isTouch }, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    const fetchOptions = async (choiceListId: number) => {
      dispatch({ type: "isLoading", payload: true });
      const client = new ClientUserQuestionChoicesClient();
      const result = await client.get(choiceListId).catch((err) => {
        showError(err);
        return [];
      });
      dispatch({ type: "fetchOptions", payload: result });
    };
    if (questionQuestionChoiceListId)
      fetchOptions(questionQuestionChoiceListId);
  }, [questionQuestionChoiceListId]);

  useEffect(() => {
    const submitAnswer = async (type: QuestionType | undefined, value: any) => {
      try {
        dispatch({ type: "isLoading", payload: true });
        const client = new ClientUserAssessmentsClient();
        await client.updateQuestionSlot(
          new UpdateClientUserAssessmentQuestionSlotCommand({
            id: questionSlotId,
            ...translateAnswer(type, value),
          })
        );
        if (xs && type !== QuestionType.Text) {
          nextPage();
        } else {
          dispatch({ type: "isLoading", payload: false });
        }
      } catch (err) {
        showError(err);
      }
    };

    if (isTouch) submitAnswer(questionType, currentAnswer);
  }, [isTouch, currentAnswer, questionType, questionSlotId]);

  useEffect(() => {
    if (xs) divRef?.current?.focus();
  }, [xs]);

  return (
    <Col span={24}>
      <QuestionSlot
        label={questionContent}
        isLoading={isLoading}
        hasFeedback
        validateStatus={
          isLoading ? "validating" : currentAnswer ? "success" : undefined
        }
        currentAnswer={currentAnswer}
        initialValue={initialAnswer}
        options={options}
        questionType={questionType}
        onInput={(value) => dispatch({ type: "submit", payload: value })}
        className={xl ? "questionSlot" : "questionSlot-mobile"}
        {...rest}
      />
      <div style={{ display: "none" }} ref={divRef} />
    </Col>
  );
};

export default SimpleQuestion;
