import React, { useEffect, useReducer, useRef, useCallback } from "react";
import { Layout } from "layout";
import { Progress, Card } from "lib";
import {
  Introduction,
  AssessmentForm,
  FormButton,
} from "@components/Assessment";
import { Row, Col, Form, Grid } from "antd";
import {
  ClientUserAssessmentsClient,
  CreateClientUserAssessmentCommand,
  IClientUserAssessmentQuestionSlotDto,
  QuestionType,
} from "@api";
import { handlePageError, showError, findAnswer } from "@action";

const AssessmentPage: React.FC<{ id: string }> = ({ id }) => {
  interface Action {
    type:
      | "nextPage"
      | "initPage"
      | "previousPage"
      | "currentForm"
      | "isLoading"
      | "createAssessment"
      | "questionSlots"
      | "submit";
    payload?: any;
  }
  const progressRef = useRef<any>();
  const initialState = {
    isInit: true,
    isLoading: true,
    progress: 0,
    forms: [],
    formPage: 0,
    currentForm: null,
    assessmentId: null,
    questionForms: [],
    assessmentTemplate: {
      name: "",
      introduction: "",
      completion: "",
    },
    totalPages: 0,
    currentFormId: 0,
    questionSlots: [],
    questionNumber: 0,
    isNextPage: true,
    isSubmitting: false,
    page: -1,
  };
  const { xs } = Grid.useBreakpoint();
  const reducer = (state: any, { type, payload }: Action) => {
    switch (type) {
      case "submit":
        return {
          ...state,
          isSubmitting: true,
        };
      case "questionSlots": {
        let newQuestionNumber =
          state.isNextPage || !xs ? 0 : payload.length - 1;
        if (state.isInit) {
          newQuestionNumber = payload.findIndex(
            (record: IClientUserAssessmentQuestionSlotDto) =>
              !findAnswer(record.formQuestion?.questionType, record)
          );

          newQuestionNumber =
            newQuestionNumber !== -1 ? newQuestionNumber : payload.length - 1;
        }
        return {
          ...state,
          questionSlots: payload,
          questionNumber: newQuestionNumber,
          isLoading: false,
          isInit: false,
        };
      }

      case "isLoading":
        return {
          ...state,
          isLoading: payload,
        };
      case "nextPage": {
        if (
          xs &&
          state.questionNumber !== state.questionSlots.length - 1 &&
          state.page !== -1
        ) {
          return {
            ...state,
            isSubmitting: false,
            questionNumber: state.questionNumber + 1,
          };
        } else {
          return {
            ...state,
            page: state.page + 1,
            isNextPage: true,
            isSubmitting: false,
            progress: ((state.page + 1) / state.totalPages) * 100,
          };
        }
      }
      case "initPage":
        return {
          ...state,
          assessmentTemplate: payload.assessmentTemplate,
          assessmentId: payload.assessmentId,
          progress: payload.progress,
          questionForms: payload.questionForms,
          totalPages: payload.totalPages,
          page: payload.page,
          isLoading: false,
        };

      case "previousPage": {
        if (xs && state.questionNumber !== 0) {
          return {
            ...state,
            questionNumber: state.questionNumber - 1,
          };
        } else {
          return {
            ...state,
            page: state.page - 1,
            isNextPage: false,
            progress: ((state.page - 1) / state.totalPages) * 100,
          };
        }
      }

      case "currentForm":
        return {
          ...state,
          currentForm: payload.form,
          currentFormId: payload.id,
          formPage: payload.pageNumbers.indexOf(state.page) + 1,
        };
      default:
        break;
    }
  };

  const [
    {
      isLoading,
      progress,
      assessmentId,
      currentForm,
      formPage,
      assessmentTemplate,
      page,
      totalPages,
      questionForms,
      currentFormId,
      questionSlots,
      questionNumber,
      isSubmitting,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const colProps = { span: 24 };
  const [form] = Form.useForm();

  const onFinish = useCallback(() => {
    const callApi = async (id: number, isSubmit: boolean) => {
      try {
        dispatch({ type: "isLoading", payload: true });
        if (isSubmit) {
          const client = new ClientUserAssessmentsClient();
          await client.submit(id);
        }
        dispatch({ type: "nextPage" });
        dispatch({ type: "isLoading", payload: false });
      } catch (err) {
        showError(err);
        window.location.reload();
      }
    };

    const isSubmit =
      page !== 0 &&
      page === totalPages - 1 &&
      (questionNumber === questionSlots.length - 1 || !xs);
    callApi(assessmentId, isSubmit);
  }, [page, totalPages, assessmentId, questionSlots, questionNumber, xs]);

  useEffect(() => {
    const fetchAssessment = async (templateId: number) => {
      try {
        let assessmentId: number;

        const client = new ClientUserAssessmentsClient();
        const templateResult = await client.getAssessmentTemplate(templateId);
        const assessments = await client.get();
        const index = assessments.findIndex(
          (assessment) =>
            assessment.clientGroupAssessmentTemplateId === templateId
        );
        if (index === -1) {
          assessmentId = await client.create(
            new CreateClientUserAssessmentCommand({
              clientGroupAssessmentTemplateId: templateId,
            })
          );
        } else {
          assessmentId = assessments[index]["id"] ?? 0;
        }
        const forms = await client.getForms(assessmentId);
        forms.sort((pre: any, cur: any) => pre.order - cur.order);
        const newTotalPage = forms.reduce(
          (preValue, currValue: any) => preValue + currValue.pageCount,
          0
        );

        let pageNumber = 0;

        const newForms = forms.map((item) => {
          const newForm = {
            ...item,
            pageNumbers: [...Array(item.pageCount).keys()].map(
              (i) => i + pageNumber
            ),
            formNumber: [[...Array(item.pageCount).keys()].map((i) => i + 1)],
          };
          pageNumber += item.pageCount ?? 0;
          return newForm;
        });

        const newProgress = index === -1 ? 0 : assessments[index]["progress"];

        const inCompletedQuestion =
          await client.getClientUserAssessmentInCompletedQuestionSlotQuery(
            assessmentId
          );

        const currentForm = newForms.find(
          (form) => form.id === inCompletedQuestion?.assessmentFormId
        );

        const currentPage =
          index !== -1 && assessments[index]?.isAnswered
            ? assessments[index]?.completedOn
              ? newTotalPage
              : currentForm
              ? currentForm.pageNumbers[
                  (inCompletedQuestion?.pageNumber ?? 0) - 1
                ]
              : newTotalPage - 1
            : -1;

        dispatch({
          type: "initPage",
          payload: {
            assessmentTemplate: templateResult,
            assessmentId: assessmentId,
            progress: newProgress,
            page: currentPage,
            totalPages: newTotalPage,
            questionForms: newForms,
          },
        });
      } catch (err) {
        handlePageError(err);
      }
    };

    fetchAssessment(+id);
  }, [id]);

  useEffect(() => {
    if (!questionForms.length) return;
    const index = questionForms.findIndex(
      (form: any) => form.pageNumbers.indexOf(page) !== -1
    );
    if (index === -1) return;
    dispatch({ type: "currentForm", payload: questionForms[index] });
    window.scrollTo({
      top: progressRef.current?.offsetTop,
      behavior: "smooth",
    });
  }, [page, questionForms, currentFormId]);

  return (
    <Layout title="AssessmentListAssessment">
      <Card
        title={assessmentTemplate.name}
        isLoading={isLoading}
        className="assessment-card"
      >
        <Row gutter={[12, 12]}>
          <Col {...colProps}>
            <div ref={progressRef}>
              <Progress size="default" percent={progress} showInfo={false} />
            </div>
          </Col>

          <Col {...colProps}>
            {page === -1 ? (
              <Introduction introduction={assessmentTemplate.introduction} />
            ) : page !== totalPages && currentForm ? (
              <AssessmentForm
                onValuesChange={() => {
                  if (
                    questionSlots?.[questionNumber]?.formQuestion
                      .questionType !== QuestionType.Text
                  )
                    dispatch({ type: "submit" });
                }}
                questionNumber={questionNumber}
                questionSlots={questionSlots}
                setQuestionSlots={(values) =>
                  dispatch({ type: "questionSlots", payload: values })
                }
                assessmentId={assessmentId}
                currentFormId={currentFormId}
                currentForm={currentForm}
                formPage={formPage}
                form={form}
                onFinish={onFinish}
                setIsLoading={(value) => {
                  dispatch({ type: "isLoading", payload: value });
                }}
              />
            ) : (
              <Introduction introduction={assessmentTemplate.completion} />
            )}
          </Col>
          <Col {...colProps}>
            <FormButton
              disabled={xs && isSubmitting}
              canSubmit={questionNumber === questionSlots.length - 1 || !xs}
              canPrevious={!!xs && questionNumber !== 0}
              page={page}
              totalPages={totalPages}
              nextPage={() => dispatch({ type: "nextPage" })}
              previousPage={() => {
                dispatch({ type: "previousPage" });
              }}
              submit={() => form.submit()}
            />
          </Col>
        </Row>
      </Card>
    </Layout>
  );
};

export default AssessmentPage;
