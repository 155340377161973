import React from "react";
import { Button, Space } from "lib";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { navigate } from "gatsby";
import { Grid } from "antd";
import { useIntl } from "react-intl";

interface IFormButton {
  page: number;
  totalPages: number;
  nextPage: () => void;
  previousPage: () => void;
  submit: () => void;
  canSubmit: boolean;
  canPrevious: boolean;
  disabled: boolean;
}

const FormButton: React.FC<IFormButton> = ({
  page,
  totalPages,
  nextPage,
  previousPage,
  submit,
  canSubmit,
  canPrevious,
  disabled,
}) => {
  const lastPage = totalPages - 1;
  const { formatMessage } = useIntl();

  const { xl } = Grid.useBreakpoint();

  const backToMyAssessmentButton = formatMessage({
    id: "AssessmentBackToMyAssessmentButton",
  });

  const agreeAndStart = formatMessage({ id: "AssessmentAgreeAndStartButton" });

  const nextButton = formatMessage({ id: "AssessmentNextPageButton" });

  const previousButton = formatMessage({ id: "AssessmentPreviousPageButton" });

  const submitButton = formatMessage({ id: "AssessmentSubmitButton" });

  switch (true) {
    case page === totalPages:
      return (
        <Space justify="flex-end">
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            {backToMyAssessmentButton}
          </Button>
        </Space>
      );
    case page === -1:
      return (
        <Space justify="flex-end">
          <Button onClick={nextPage}>{agreeAndStart}</Button>
        </Space>
      );
    case page === 0 && page !== lastPage:
      return (
        <Space justify={canPrevious ? "space-between" : "flex-end"}>
          {canPrevious && (
            <Button type="default" onClick={previousPage} disabled={disabled}>
              <CaretLeftOutlined />
              {previousButton}
            </Button>
          )}

          <Button onClick={submit} disabled={disabled}>
            {nextButton}
            <CaretRightOutlined />
          </Button>
        </Space>
      );
    case page !== 0 && page !== lastPage:
      return (
        <Space justify={xl ? "flex-end" : "space-between"}>
          <Button type="default" onClick={previousPage} disabled={disabled}>
            <CaretLeftOutlined />
            {previousButton}
          </Button>
          <Button onClick={submit} disabled={disabled}>
            {nextButton}
            <CaretRightOutlined />
          </Button>
        </Space>
      );
    case page === 0 && page === lastPage:
      return (
        <Space justify={canPrevious ? "space-between" : "flex-end"}>
          {canPrevious && (
            <Button type="default" onClick={previousPage} disabled={disabled}>
              <CaretLeftOutlined />
              {previousButton}
            </Button>
          )}
          <Button onClick={submit} disabled={disabled}>
            {canSubmit ? submitButton : nextButton}
            <CaretRightOutlined />
          </Button>
        </Space>
      );
    case page !== 0 && page === lastPage:
      return (
        <Space justify={xl ? "flex-end" : "space-between"}>
          <Button type="default" onClick={previousPage} disabled={disabled}>
            <CaretLeftOutlined />
            {previousButton}
          </Button>
          <Button onClick={submit} disabled={disabled}>
            {canSubmit ? submitButton : nextButton}
            <CaretRightOutlined />
          </Button>
        </Space>
      );

    default:
      return <></>;
  }
};

export default FormButton;
