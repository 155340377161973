import React from "react";
import { Row, Col, Typography, Affix } from "antd";
import SimpleQuestion from "./SimpleQuestion";
import {
  IClientUserAssessmentQuestionSlotDto,
  IClientUserRatingScaleDto,
} from "@api";

interface IMatrixQuestions {
  questionSlots?: IClientUserAssessmentQuestionSlotDto[];
  ratingScale?: IClientUserRatingScaleDto;
  nextPage: () => void;
}

const MatrixQuestions: React.FC<IMatrixQuestions> = ({
  questionSlots = [],
  ratingScale,
  nextPage,
}) => {
  const { Text } = Typography;
  return (
    <Row gutter={[0, 12]}>
      <Col xl={12} xs={0} />
      <Col xl={12} xs={0}>
        <Affix offsetTop={24}>
          <div style={{ background: "#ffffff" }}>
            <Row>
              {ratingScale?.ratingScalePoints?.map((item, index: number) => (
                <Col
                  key={index}
                  xl={Math.floor(24 / (ratingScale?.pointOfScale ?? 1))}
                  xs={0}
                  style={{ textAlign: "center", transform: "translateX(-4px)" }}
                >
                  <Text strong>{item.label}</Text>
                </Col>
              ))}
            </Row>
          </div>
        </Affix>
      </Col>

      {questionSlots.map(
        (questionSlot: IClientUserAssessmentQuestionSlotDto) => (
          <SimpleQuestion
            nextPage={nextPage}
            key={`questionSlot${questionSlot.id}`}
            name={`questionSlot${questionSlot.id}`}
            questionAnswer={questionSlot}
            questionSlotId={questionSlot.id}
            {...questionSlot.formQuestion}
            options={ratingScale?.ratingScalePoints?.map((item) => ({
              label: item.label,
              value: item.id ?? 0,
            }))}
          />
        )
      )}
    </Row>
  );
};

export default MatrixQuestions;
